<template>
  <b-card>
    <g-table
      ref="suppliers-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      @filtered="onFiltered"
      :createButton="{ visiable: true, permission: 'addSuppliers'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({
            id: 0,
            englishName: ''
          });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="currentBranch.id === item.branchId ? $t('edit') : $t('preview')"
            v-permission="'editSuppliers'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              :icon="currentBranch.id === item.branchId ? 'EditIcon' : 'EyeIcon'"
              class="mx-25 clickable"
            />
          </b-button>
          <b-button
            v-if="currentBranch.id === item.branchId"
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteSuppliers'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>

    <b-sidebar
      id="sidebar-edit"
      ref="sidebar"
      no-close-on-backdrop
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <template #default>
        <div
          class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-white">
            {{ selectedItem.id > 0 ? $t("editSupplier") : $t("newSupplier") }}
          </h5>
        </div>

        <b-card>
          <g-form @submit="save">
            <!-- code  -->
            <b-row v-show="selectedItem.id > 0">
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="code"
                    type="number"
                    name="code"
                    label-text="code"
                    readonly
                    :value.sync="selectedItem.code"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- arabic name  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="arabicName"
                    name="arabicName"
                    label-text="arabicName"
                    rules="required"
                    :value.sync="selectedItem.arabicName"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- english name  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="englishName"
                    name="englishName"
                    label-text="englishName"
                    :value.sync="selectedItem.englishName"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- tax Number  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="taxNumber"
                    name="taxNumber"
                    label-text="taxNumber"
                    :value.sync="selectedItem.taxNumber"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- commercial Register Number  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="commercialRegisterNumber"
                    name="commercialRegisterNumber"
                    label-text="commercialRegisterNumber"
                    :value.sync="selectedItem.commercialRegisterNumber"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- contact Number  -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    id="contactNumber"
                    name="contactNumber"
                    label-text="contactNumber"
                    :value.sync="selectedItem.contactNumber"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="general"
                >
                  {{ $t("general") }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isShared"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="checkIfCurrentItemUsedInAnotherBranch()"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- notes -->
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label
                    class="mb-1"
                    style="font-size: 14px"
                  >
                    {{ $t("notes") }}
                  </label>
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label="notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- opertaions -->
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-if="currentBranch.id === selectedItem.branchId || selectedItem.id === 0"
                  v-permission="$route.meta.permission"
                  class="mx-1"
                  type="submit"
                  variant="primary"
                  data-action-type="save"
                >
                  {{ $t("save") }}
                </b-button>
                <b-button
                  class="ml-1"
                  variant="secondary"
                  @click="closeModal"
                >
                  {{ $t("Close") }}
                </b-button>
              </b-col>
            </b-row>
          </g-form>
          <div
            v-if="helper.showExcelWithTransactions"
            class="center-element flex-column mt-2"
          >
            <vue-excel-xlsx
              :data="helper.transactionsInAnotherBranch"
              :columns="sharedTransactionsColumns"
              :filename="fileName"
              :sheetname="'xlsxSheet'"
              class="btn btn-relief-success ml-1 p-0 btn-sm"
            >
              <img
                src="@/assets/images/icons/xls.jpg"
                alt=""
                style="height: 39px; width: auto"
              >
            </vue-excel-xlsx>
            <label
              class="main-space mt-1 mb-2"
              style="color: #177219;"
            >
              {{ $t('sharedTransactionHint') }} </label>
          </div>
        </b-card>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },

  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 500,
      rows: rows,
      selectedItem: {},
      sharedItems: [],
      helper: {
        isCurrentStateShared: false,
        transactionsInAnotherBranch: [],
        showExcelWithTransactions: false
      }
    };
  },

  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('arabicName'), sortable: true },
        { key: 'taxNumber', label: this.$t('taxNumber'), sortable: true },
        { key: 'contactNumber', label: this.$t('contactNumber'), sortable: true },
        { key: 'notes', label: this.$t('notes'), sortable: true },
        { key: 'actions' },
      ];
    },

    sharedTransactionsColumns() {
      return [
        { key: 'branchNameAr', field: 'branchNameAr', label: this.$t('branchName') },
        { key: 'sourceValue', field: 'sourceValue', label: this.$t('transactionNumber') },
        { key: 'sourceName', field: 'sourceName', label: this.$t('invoiceType') },
      ];
    },

    fileName() {
      return `${this.$t('sharedTransactionsForSupplier')} - ${this.selectedItem.arabicName}`
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItems() {
      this.get({ url: 'suppliers' }).then((data) => {
        // get All Items
        this.items = data;

        // is existing shared entity
        this.sharedItems = data.filter((x) => x.branchId !== this.currentBranch.id);

        // if any then show it for user
        if (this.sharedItems.length > 0) {
          this.tableColumns.splice(5, 0, {
              key: 'isShared',
              keyfield: 'isShared',
              label: this.$t('general'),
              formatter: (key, value, item) => {
                if (item.branchId !== this.currentBranch.id) {
                  return `<span class=""><img src="${require('@/assets/images/icons/valid.png')}" width="25" alt="isShared"></span>`
                }
              },
          })
          this.tableColumns.splice(6, 0, {
              key: 'baseBranch',
              kefieldy: 'baseBranch',
              label: this.$t('branchName'),
              formatter: (key, value, item) => {
                if (item.branchId !== this.currentBranch.id) {
                  return `<span class="p-50 main-space badge bg-gradient-primary bg-darken-1">${this.branches.find((x) => x.id === item.branchId).arabicName}</span>`;
                }
              },
          })
        }
      });
    },

    save() {
      // validate
      if (this.helper.isCurrentStateShared && !this.selectedItem.isShared && this.helper.transactionsInAnotherBranch.length > 0) {
        this.doneAlert({
            title: this.$t('cantUpdate'),
            text: this.$t('usedInTransactionsInAnotherBranches', { name: this.isRight ? this.selectedItem.arabicName : this.selectedItem.englishName }),
            type: 'error',
            timer: 10000
        })
        this.helper.showExcelWithTransactions = true;
        return;
      }

      // then save
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'suppliers',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'suppliers', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'suppliers', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },

    closeModal() {
      // reset
      this.helper = {
        isCurrentStateShared: false,
        transactionsInAnotherBranch: [],
        showExcelWithTransactions: false
      }
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.helper.isCurrentStateShared = item.isShared;
      this.selectedItem = { ...item };
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
    checkIfCurrentItemUsedInAnotherBranch() {
      if (this.branches.length > 1 && this.currentBranch.id === this.selectedItem.branchId && this.helper.isCurrentStateShared && !this.selectedItem.isShared) {
        this.get({ url: 'Suppliers/getSharedSuppliers', id: this.selectedItem.id }).then((data) => {
          this.helper.transactionsInAnotherBranch = data;
          this.helper.transactionsInAnotherBranch.forEach(element => {
            element.sourceName = this.$t(element.sourceType)
          });
        });
      }
    }
  },
};
</script>

<style></style>
